<template>
    <b-row class="mt-2">
        <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Vital Signs</h5>
            <small class="text-muted">Enter the corresponding vital signs</small>
        </b-col>
        <b-col md="6" v-for="(item, id) in signVitalsOptions" :key="item.id">
            <b-form-group :label="item.name" :label-for="item.prefix" v-if="item.id != 1">
                <validation-provider #default="{ errors }" :name="item.name" rules="required">
                    <b-input-group :append="item.unit" class="input-group-merge">
                        <b-form-input :id="item.prefix" v-model="vitalSign[id].value"
                        :state="errors.length > 0 ? false : null" class="form-control" :placeholder="item.unit"
                        :disabled="item.id == 8" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
            <b-form-group :label="item.name" :label-for="item.prefix" v-else>
                <validation-provider #default="{ errors }" :name="item.name" rules="required">
                    <b-input-group :append="appendItem(item.unit, vitalSign[id].value)" class="input-group-merge">
                        <b-form-input :id="item.prefix" v-model="vitalSign[id].value"
                        :state="errors.length > 0 ? false : null" class="form-control" :placeholder="item.unit"
                        :disabled="item.id == 8" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
        </b-col>
    </b-row>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { BRow, BCol, BFormGroup, BInputGroup, BFormInput } from "bootstrap-vue";
export default {
    components: {
        ValidationProvider,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BFormInput,
    },
    props: {
        vitalSign: {
            type: Array,
            required: true,
        },
        signVitalsOptions: {
            type: Array,
            required: true,
        },
    },
    watch: {
        vitalSign: {
            handler() {
                this.calculateIBM()
            },
            deep: true
        },
    },
    methods: {
        appendItem(name, value) {
            if (value == null) return name
            let arrayXd = value.split('')
            let numTotal = ''
            if (+arrayXd[0] < 2) {
                if (value.length >= 3) {
                    arrayXd.splice(3, 0, '/')
                    numTotal = arrayXd.join('')
                    return numTotal
                }
            } else {
                arrayXd.splice(2, 0, '/')
                numTotal = arrayXd.join('')
                return numTotal
            }
            return name
        },
        calculateIBM() {
            const wieghtData = parseInt(this.vitalSign[5].value);
            const heightData = parseInt(this.vitalSign[6].value);
            // Fórmula: peso (lb) / [estatura (in)]2 x 703
            const result = Math.round(((wieghtData / Math.pow(heightData, 2)) * 703) * 100) / 100;

            this.vitalSign[7].value = Number.isNaN(result) ? 0 : result;
        },
    },
};
</script>

<style></style>
