<template>
  <div>
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">Medical History</h5>
        <small class="text-muted">Enter the patient's medical history.</small>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <validation-provider #default="{ errors }" name="PMHx">
          <b-form-group
            label-for="pmhx"
            :state="errors.length > 0 ? false : null"
          >
            <div class="d-flex justify-content-between">
              <label for="">PMHx</label>
              <!-- <b-button
                size="sm"
                variant="success"
                class="btn-icon mb-1"
                @click="isAddNewAilmentSidebarActive = true"
              >
                <i class="wizard-icon fas fa-plus-circle"></i>
              </b-button> -->
            </div>
            <treeselect
              id="pmhx"
              v-model="medicalHistory.ailments"
              :load-options="searchAilment"
              :async="true"
              :defaultOptions="ailmentOptions.data"
              :normalizer="normalizer"
              multiple
            >
            </treeselect>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="6">
        <validation-provider #default="{ errors }" name="Allergies">
          <b-form-group
            label-for="allergies"
            :state="errors.length > 0 ? false : null"
          >
            <div class="d-flex justify-content-between">
              <label for="">Allergies</label>
              <!-- <b-button
                variant="success"
                class="btn-icon mb-1"
                size="sm"
                @click="isAddNewAllergySidebarActive = true"
              >
                <i class="wizard-icon fas fa-plus-circle"></i>
              </b-button> -->
            </div>
            <treeselect
              id="allergies"
              v-model="medicalHistory.allergies"
              :normalizer="normalizer"
              multiple
              :load-options="searchAllergies"
              :async="true"
              :defaultOptions="allergiesOptions.data"
            >
            </treeselect>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="12">
        <validation-provider #default="{ errors }" name="Medications">
            <b-form-group
              label-for="medications"
              :state="errors.length > 0 ? false : null"
            >
              <div class="d-flex justify-content-between">
                <label for="">Medications</label>
              </div>
              <treeselect
                id="medications"
                v-model="medicalHistory.medications"
                :normalizer="normalizer"
                multiple
                :load-options="searchMedications"
                :async="true"
                :defaultOptions="medicationsOptions.data"
              >
              </treeselect>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        <!-- <b-card>
          <div class="d-flex align-items-center justify-content-end mb-2">
            <b-button
              :disabled="loading"
              variant="primary"
              @click="isAddNewMedicationUserSidebarActive = true"
            >
              <span v-if="!loading" class="text-nowrap"
                >{{ $t("Add") }} Medication User</span
              >
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
          </div>
          <b-table
            :items="medicalHistoryMutable"
            :fields="fields"
            striped
            responsive
            hover
            class="position-relative text-center"
            show-empty
            empty-text="No matching records found"
          >
            <template #cell(note)="data">
              <div class="overflow-auto" style="height: 100px">
                {{ data.item.note }}
              </div>
            </template>
            <template #cell(status_medications_id)="data">
              <b-button-group size="sm" role="group" id="toolBtns">
                <b-button
                  v-for="state in stateMedications"
                  :key="state.id"
                  class="btn btn-icon"
                  type="button"
                  variant="outline-secondary"
                  v-b-tooltip.hover.top="state.name"
                  :class="{
                    active: data.item.status_medications_id === state.id,
                  }"
                  :style="{
                    background:
                      data.item.status_medications_id === state.id
                        ? state.color
                        : '',
                    color:
                      data.item.status_medications_id === state.id
                        ? 'white'
                        : '',
                  }"
                >
                  {{ state.prefix }}
                </b-button>
              </b-button-group>
            </template>
            <template #cell(actions)="data">
              <div>
                <b-button
                  variant="outline-primary"
                  class="btn-icon btn-circle border-0"
                  v-b-tooltip.hover.top="'Update'"
                  @click="editMedication(data.item)"
                >
                   <i class="fas fa-pen"></i>
                </b-button>

                <b-button
                  variant="outline-danger"
                  class="btn-icon btn-circle border-0"
                  v-b-tooltip.hover.top="'Delete'"
                  @click="deleteMedication(data.item)"
                >
                  <i class="far fa-trash-alt"></i>
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BCard,
  BTable,
  BFormCheckbox,
  BFormInvalidFeedback,
  BButtonGroup,
  BButton,
  VBTooltip,
} from "bootstrap-vue";

import axiosAI from "@/core/services/api/admin/consults/ailments";
import axiosAL from "@/core/services/api/admin/consults/allergies";
import axiosMD from "@/core/services/api/admin/consults/medications";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BCard,
    BTable,
    BFormCheckbox,
    BFormInvalidFeedback,
    BButtonGroup,
    BButton,
    VBTooltip,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    medicalHistory: {
      type: Object,
      required: true,
    },
    allergiesOptions: {
      type: Object,
      required: true,
    },
    ailmentOptions: {
      type: Object,
      required: true,
    },
    medicationsOptions: {
      type: Object,
      required: true,
    },
    medicationsPatientOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      test: [
        {
          id: 1,
          name: "Taking",
          prefix: "T",
          color: "#d1192b",
        },
      ],
      medicalHistoryMutable: this.medicalHistory.medicationInformation,
      medicationsPatient: [],
      stateMedications: [
        {
          id: 1,
          name: "Taking",
          prefix: "T",
          color: "#d1192b",
        },
        {
          id: 2,
          name: "Not Taking",
          prefix: "N",
          color: "#120b75",
        },
        {
          id: 3,
          name: "Discontinued",
          prefix: "D",
          color: "#12a312",
        },
        {
          id: 4,
          name: "Unknown Status",
          prefix: "U",
          color: "#ffd900",
        },
      ],
      activeBtn: "",
      fields: [
        // A column that needs custom formatting
        { key: "medications.name", label: "Name", sortable: true },
        { key: "start_date", label: "Start Date", sortable: true },
        { key: "start_stop", label: "Stop Date", sortable: true },
        { key: "note", label: "note", sortable: true, class: "th-class-note" },
        // A virtual column made up from two fields
        { key: "status_medications_id", label: "Status", sortable: true },
        { key: "actions", label: "Actions" },
      ],
      loading: false,
      setTimeoutBuscador: "",
      isAddNewAilmentSidebarActive: false,
      isAddNewAllergySidebarActive: false,
      isAddNewMedicationUserSidebarActive: false,
      isEditMedicationSidebarActive: false,
      itemEdit: null,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  mounted() {
    this.medicalHistory.medications = this.medicationsPatientOptions.data.map((medication) => {
        return medication.id
    })
  },
  methods: {
    searchAllergies({ action, searchQuery, callback }) {
      if (action === "ASYNC_SEARCH") {
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: searchQuery,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosAL.allergyFilter(10, datos).then(({ data }) => {
            callback(null, data);
          });
        }, 350);
      }
    },
    searchMedications({ action, searchQuery, callback }) {
      if (action === "ASYNC_SEARCH") {
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: searchQuery,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosMD.medicationFilter(10, datos).then(({ data }) => {
            callback(null, data);
          });
        }, 350);
      }
    },
    searchAilment({ action, searchQuery, callback }) {
      if (action === "ASYNC_SEARCH") {
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: searchQuery,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosAI.ailmentFilter(10, datos).then(({ data }) => {
            callback(null, data);
          });
        }, 350);
      }
    },
  },
};
</script>

<style></style>
