<template>
    <b-row class="mt-2">
        <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Review System</h5>
            <small class="text-muted">Enter the review system</small>
        </b-col>
        <b-col md="6" xl="3" class="my-1" v-for="item in reviewSystemLength.data" :key="item.id">
            <b-form-group :label="item.name" :label-for="item.name">
                <validation-provider #default="{ errors }" :name="item.name">
                    <treeselect :id="item.name" v-model="reviewSystemSelected[item.name]" :options="item.review_of_system"
                        :normalizer="normalizer" multiple>
                    </treeselect>
                    <!-- <v-select
            :id="item.name"
            v-model="reviewSystemSelected[item.name]"
            :options="item.review_of_system"
            multiple
            :reduce="(value) => value.id"
            label="name"
          /> -->
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
        </b-col>
        <b-col>
            <b-form-group label="Description of review">
                <validation-provider #default="{ errors }" name="Description of review">
                    <b-form-textarea @change="$emit('text', $event)" v-bind:describe_review_system="describe_review_system"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
        </b-col>
        <!-- <b-col md="3" class="d-flex flex-column">
        <label for="">{{ $t("Add") }} Review System</label>
        <b-button
          variant="success"
          class="btn-icon"
          @click="isAddNewReviewSystemSidebarActive = true"
        >
          <i class="wizard-icon fas fa-plus-circle"></i>
        </b-button>
      </b-col> -->
    </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup, BButton, BFormTextarea } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol,
        BFormGroup,
        BButton,
        BFormTextarea
    },
    model: {
        prop: 'describe_review_system',
        event: 'text'
    },
    props: {
        describe_review_system: {
            type: String,
            default: '',
            required: false,
        },
        reviewSystemSelected: {
            type: Object,
            required: true,
        },
        reviewSystemLength: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isAddNewReviewSystemSidebarActive: false,
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                };
            },
        };
    },
};
</script>

<style></style>
