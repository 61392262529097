<template>
  <div>
    <b-row class="mt-2">
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">Patient Details</h5>
        <small class="text-muted"> Enter patient details </small>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group :label="$t('Form.Name')" label-for="name">
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="detailPatient.name"
              :state="errors.length > 0 ? false : null"
              placeholder="Vincent"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Form.Last Name')" label-for="lastname">
          <validation-provider
            #default="{ errors }"
            name="lastname"
            rules="required"
          >
            <b-form-input
              id="lastname"
              v-model="detailPatient.lastName"
              :state="errors.length > 0 ? false : null"
              placeholder="Miller"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('Form.Phone')" label-for="phone">
          <validation-provider
            #default="{ errors }"
            name="Phone"
            rules="required"
          >
            <b-form-input
              id="phone"
              class="form-control"
              v-model="formatPhone"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="DOB" label-for="date_birth">
          <validation-provider
            #default="{ errors }"
            name="DOB"
            rules="required"
          >
            <flat-pickr
              id="date_birth"
              v-model="detailPatient.dateBirth"
              :config="config"
              class="form-control"
              placeholder="MM-DD-YYYY"
              type="date"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Facility" label-for="facility">
          <validation-provider
            #default="{ errors }"
            name="Facility"
            rules="required"
          >
            <b-form-input
              id="facility"
              v-model="detailPatient.facility"
              disabled
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <validation-provider #default="{ errors }" name="Room" rules="required">
          <b-form-group
            :label="$t('Form.Room')"
            label-for="room"
            :state="errors.length > 0 ? false : null"
          >
            <treeselect
              v-model="detailPatient.room"
              :options="roomOptions"
              :normalizer="normalizer"
            >
              <!-- @input="verifyRoom" -->
            </treeselect>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col>
        <validation-provider #default="{ errors }" name="Gender" rules="required">
            <label>{{ $t("Form.Gender") }}</label>
            <treeselect
              v-model="detailPatient.gender"
              :options="genderOptions"
              :normalizer="normalizer"
              :disabled="detailPatient.type_visits_id != 3"
            />
        </validation-provider>
      </b-col>
      <b-col>
        <validation-provider #default="{errors}" name="Visit reason" rules="required">
            <b-form-group label-for="visit_reason">
                <label for="type_consult">{{ $t("Visit Reason") }}</label>
                <b-form-textarea v-model="detailPatient.reason_visit"
                    :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback>
                    {{ errors[0] }}
                </b-form-invalid-feedback>
            </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormTextarea, BFormGroup } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BRow,
    BCol,
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    detailPatient: {
      type: Object,
      required: true,
    },
    roomOptions: {
      type: Array,
      required: false,
    },
  },
  computed: {
    formatPhone: {
      get() {
        let { phone } = this.detailPatient;
        phone = phone.toString().replace(/\D/g, "");
        const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
          phone = `(${match[1]}${match[2] ? "" : ""}) ${match[2]}${
            match[3] ? "-" : ""
          }${match[3]}`;
        }
        return phone;
      },
      set(val) {
        this.detail.phone = val;
      },
    },
  },
  data() {
    return {
      required,
      config: {
        dateFormat: "m-d-Y",
        allowInput: true,
      },
      genderOptions: [
        { name: this.$t("Male"), id: "male" },
        { name: "Female", id: "female" },
      ],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name ? node.name : node.id,
        };
      },
    };
  },
};
</script>

<style></style>
